export const preapprovedTemplate = async (number) => {
    const url = "https://graph.facebook.com/v19.0/100263549385166/messages";
    const data = {
        messaging_product: "whatsapp",
        to: "91" + number,
        type: "template",
        template: {
            name: "akount_app_dowload",
            language: {
                code: "en",
            },
            components: [
                {
                    type: 'header',
                    parameters: [
                        {
                            type: 'image',
                            image: {
                                link: 'http://akount.inventics.tech/assets/images/banner/banner.png'
                            }
                        }
                    ]
                },
            ]
        }
    };

    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                Authorization:
                    "Bearer EAApDmi22WZA0BOZBEZBih8mcwcB7hEY2zBiJiJsGo0GiGq4ZCP1CRwHdIWyLyURhzV8E0CPYn5xbd9ofug3aKBrdfAeeSa9GBaoqBFkYhTPVoLrX2O2ivjkO19C3Try0I3wwmGFB9ZAiT3ZCGpZCiq4z67hyivmv7YoDU0POT85PI74N8Q5coVxFZB8ZCaZA0PPZCOKYa8NMHx9d0iG4yAg",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            throw new Error(
                `HTTP error: ${response.status} Response: ${await response.text()}`
            );
        }

        const result = await response.json();
        return result;

    } catch (error) {
        console.error("Fetch error:", error);
        throw new Error("Request failed");
    }
}