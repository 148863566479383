import React,{useEffect} from "react";

function PrivacyPolicy(){
    useEffect(() => {
        const header = document.querySelector('.oppi_header');
    
        const handleScroll = () => {
            if (!header.classList.contains('fixedMenu')) {
                header.classList.add('fixedMenu');
              }
          } 
        // Call handleScroll on page load
        handleScroll();
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);


    return(
        <>
        <section className="oppi_feature_wrap">
            <div className="d-flex justify-content-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-12">
                            <h2 className="text-center sec_title wow fadeInUp" data-wow-delay="0.2s">Privacy Policy</h2>
                            <p>This Privacy Policy outlines how Akount ("we," "our," or "us") collects, uses, maintains, and protects information gathered from users ("you" or "User") of the Akount mobile application ("App").</p>
                            <span><b>1. Information We Collect</b></span>
                            <p className="para">We may collect personal information such as your name, email address, phone number, and billing details when you register or use our App.
                            We may collect non-personal information about how you interact with our App, including device information, IP address, browser type, and operating system.
                            If you make purchases through our App, we may collect payment information, such as credit card details, to process transactions securely.</p>
                            <span><b>2. How We Use Collected Information</b></span>
                            <p className="para">We may use your information to personalize your experience and provide tailored content and services. We may use your email address to send you important updates, newsletters, promotions, and other information related to our services. 
                            We use payment information to process transactions and manage subscriptions. We may use usage information for analytical purposes to improve our App's performance, functionality, and user experience.</p>
                            <span><b>3. How We Protect Your Information</b></span>
                            <p className="para">We implement appropriate security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information.
                            Your payment information is encrypted and securely processed using industry-standard security protocols.</p>
                            <span><b>4. Sharing Your Information</b></span>
                            <p className="para">We do not sell, trade, or rent your personal information to third parties. However, we may share your information with trusted third-party service providers who assist us in operating our App and conducting business, as long as they agree to keep this information confidential.</p>
                            <span><b>5. Your Rights and Choices</b></span>
                            <p className="para">You may choose not to provide certain personal information, but this may limit your ability to access certain features of the App.
                            You may opt-out of receiving promotional emails by following the unsubscribe instructions provided in the email.</p>
                            <span><b>6. Changes to This Privacy Policy</b></span>
                            <p className="para">We reserve the right to update or change our Privacy Policy at any time. Any changes will be reflected on this page with a revised "Last Updated" date.</p>
                            <span><b>7. Contact Us</b></span>
                            <p className="para">If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at info@inventics.tech.</p>
                            * By using our App, you signify your acceptance of this Privacy Policy.
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}
export default PrivacyPolicy;