import React from 'react'
import {Link} from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const Header = () => {
    return (
        <header className="oppi_header oppi_header_transparent">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                        <nav className="navbar navbar-expand-lg header_nav">
                            <Link className="navbar-brand logo" to="/">
                                <img src="/assets/images/akount.png" alt="logo"/>
                                <img src="/assets/images/akount2.png" alt="logo"/>
                            </Link>
                            <button className="navbar-toggler oppi_hamburger" type="button" data-toggle="collapse" data-target="#oppi_header_menu" aria-expanded="false">
                                <span className="bar_icon">
                                    <span className="bar bar_1"></span>
                                    <span className="bar bar_2"></span>
                                    <span className="bar bar_3"></span>
                                </span>
                            </button>

                            <div className="collapse navbar-collapse oppi_header_menu" id="oppi_header_menu">
                                <ul className="navbar-nav ml-auto">
                                    <li className="nav-item active dropdown">
                                        <HashLink className="nav-link " smooth to="/#" > Home </HashLink>
                                        {/* <ul className="dropdown-menu">
                                            <li><Link to="home_business_apps.html">Home Business Apps</Link></li>
                                            <li><Link to="home_education_apps.html">Home education Apps</Link></li>
                                            <li><Link to="home_finance.html">Home Finance</Link></li>
                                            <li><Link to="home_food_app.html">Home Food App</Link></li>
                                            <li><Link to="home_health_apps.html">Home Health Apps</Link></li>
                                            <li><Link to="home_lifestyle_apps.html">Home Lifestyle Apps</Link></li>
                                            <li><Link to="home_restaurant_app.html">Home Restaurant App</Link></li>
                                            <li><Link to="home_social_media_apps.html">Home Social Media Apps</Link></li>
                                            <li><Link to="home_travel_apps.html">Home Travel Apps</Link></li>
                                            <li><Link to="home_utility_tools_apps.html">Home Utility Tools Apps</Link></li>
                                        </ul> */}
                                    </li>
                                    {/* <li className="nav-item dropdown">
                                        <Link className="nav-link dropdown-toggle" to="#" data-toggle="dropdown"> Case Study </Link>
                                        <ul className="dropdown-menu">
                                            <li><Link to="case_study_style_01.html">Case Study Style 01</Link></li>
                                            <li><Link to="case_study_style_02.html">Case Study Style 02</Link></li>
                                            <li><Link to="case_study_style_03.html">Case Study Style 03</Link></li>
                                            <li><Link to="case_study_single.html">Case Study Single</Link></li>
                                        </ul>
                                    </li> */}
                                    {/* <li className="nav-item dropdown">
                                        <Link className="nav-link dropdown-toggle" to="#" data-toggle="dropdown"> Pages </Link>
                                        <ul className="dropdown-menu">
                                            <li><Link to="how_it_works_style_01.html">How it works</Link></li>
                                            <li><Link to="download.html">Download</Link></li>
                                            <li><Link to="pricing_pro.html">Pricing</Link></li>
                                            <li><Link to="sign_in.html">Sign In</Link></li>
                                            <li><Link to="sign_up.html">Sign Up</Link></li>
                                            <li><Link to="Integrations.html">Integrations</Link></li>
                                            <li><Link to="support.html">Support</Link></li>
                                        </ul>
                                    </li> */}
                                    <li className="nav-item ">
                                        <HashLink className="nav-link " smooth to="/#oppi_how_it_works_wrap" > Features </HashLink>
                                        {/* <ul className="dropdown-menu">
                                            <li><Link to="blog_sidebar.html">Blog</Link></li>
                                            <li><Link to="blog_single_sidebar.html">Blog Single</Link></li>
                                        </ul> */}
                                    </li>
                                    <li className="nav-item">
                                        <HashLink className="nav-link" smooth to="/#oppi_support_wrap">Get in Touch</HashLink>
                                    </li>
                                </ul>
                                <HashLink className="nav-link oppi_try_for_free " smooth to="/#oppi_download_app_wrap">Download for Free</HashLink>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header
