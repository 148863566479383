import React from 'react'
import HomeBanner from '../components/HomeBanner';
import {Link} from 'react-router-dom';
function Home(){
    return (
        <>
            <HomeBanner />
            
            <section className="oppi_feature_wrap">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-12">
                            <h2 className="text-center sec_title wow fadeInUp" data-wow-delay="0.2s">Why small Business Owners <br/> Love Akount.</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="oppi_feature_item">
                                <div className="feature_img text-center wow fadeInUp" data-wow-delay="0.2s">
                                    <img src="/assets/images/feature/feature_img_1.jpg" alt="feature_img" className="img-fluid"/>
                                </div>
                                <Link to="#">
                                    <h4 className="feature_title text-center wow fadeInUp" data-wow-delay="0.3s">Regular updates as per updating regulations</h4>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="oppi_feature_item">
                                <div className="feature_img text-center wow fadeInUp" data-wow-delay="0.4s">
                                    <img src="/assets/images/feature/feature_img_2.jpg" alt="feature_img" className="img-fluid"/>
                                </div>
                                <Link to="#">
                                    <h4 className="feature_title text-center wow fadeInUp" data-wow-delay="0.6s">Quick accounting for customer & supplier</h4>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="oppi_feature_item">
                                <div className="feature_img text-center wow fadeInUp" data-wow-delay="0.4s">
                                    <img src="/assets/images/feature/feature_img_3.jpg" alt="feature_img" className="img-fluid"/>
                                </div>
                                <Link to="#">
                                    <h4 className="feature_title text-center wow fadeInUp" data-wow-delay="0.5s">Instant support for all your app related queries</h4>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- oppi_feature_wrap --> */}

            <section className="oppi_how_it_works_wrap" id="oppi_how_it_works_wrap">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-12">
                            <h2 className="text-center sec_title wow fadeInUp" data-wow-delay="0.2s">A Collection of <br/> Advanced features</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-12">
                            <div className="oppi_how_it_tab_wrap wow fadeInUp" data-wow-delay="0.4s">
                                <nav>
                                    <ul className="nav nav-tabs oppi_how_it_tab">
                                        <li className="nav-item">
                                            <a className="nav-link active" data-toggle="tab" href="#Invoicing" >Invoicing</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#Expenses" >Expenses</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#Products" >Products</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#Contacts" >Contacts</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#Employee" >Employee</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#Payments" >Payment</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#Reporting" >Reporting</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="oppi_how_it_tab_content_wrap">  
                    <div className="oppi_how_it_tab_content">
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="Invoicing" role="tabpanel">
                                <div className="oppi_mobile_mockup_slider">
                                    <div className="oppi_mobile_mockup_slider_item">
                                        <div className="container">
                                            <div className="row d-flex align-items-center">
                                                <div className="col-md-6 col-sm-12 col-12">
                                                    <div className="oppi_mobile_mockup">
                                                        <img src="/assets/images/akountGraphics/invoices.png" alt="iphoneX" className="img-fluid mockup_1"/>
                                                        <img src="/assets/images/akountGraphics/invoices_all.png" alt="iphoneX" className="img-fluid mockup_2"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-12 col-12">
                                                    <div className="oppi_mockup_content">
                                                        <h3 className="title">Work anywhere with versatile mobile invoicing feature
                                                        </h3>
                                                        <p className="para">Easily create and manage invoices on the go with Akount. Whether you're at the office, in a coffee shop, or on the move, our mobile app empowers you to stay on top of your invoicing tasks seamlessly.</p>
                                                        <p className="para">No more delays or missed opportunities—Akount ensures that you can send professional invoices anytime, anywhere. Say goodbye to the hassle of traditional invoicing and hello to convenience with Akount!</p>
                                                        <Link to="#" className="oppi_dbtn btn_border">Try it Free</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="Expenses" role="tabpanel">
                                <div className="oppi_mobile_mockup_slider">
                                    <div className="oppi_mobile_mockup_slider_item">
                                        <div className="container">
                                            <div className="row d-flex align-items-center">
                                                <div className="col-md-6 col-sm-12 col-12">
                                                    <div className="oppi_mobile_mockup">
                                                        <img src="/assets/images/akountGraphics/expenses.png" alt="iphoneX" className="img-fluid mockup_1"/>
                                                        <img src="/assets/images/akountGraphics/expenses_2.png" alt="iphoneX" className="img-fluid mockup_2"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-12 col-12">
                                                    <div className="oppi_mockup_content">
                                                        <h3 className="title">Seamless Expense Management with Akount App
                                                        </h3>
                                                        <p className="para">Akount empowers you to effortlessly manage your expenses on the go. Whether you're at the office, traveling, or enjoying a coffee break, our mobile app ensures that you can stay on top of your expenses with ease.</p>
                                                        <p className="para">Forget the hassle of manual expense tracking. With Akount, you can maintain a clear overview of your finances and make informed decisions wherever you are. Experience the convenience of streamlined expense management with Akount.</p>
                                                        <Link to="#" className="oppi_dbtn btn_border">Try it Free</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="Products" role="tabpanel">
                                <div className="oppi_mobile_mockup_slider">
                                    <div className="oppi_mobile_mockup_slider_item">
                                        <div className="container">
                                            <div className="row d-flex align-items-center">
                                                <div className="col-md-6 col-sm-12 col-12">
                                                    <div className="oppi_mobile_mockup">
                                                        <img src="/assets/images/akountGraphics/products.png" alt="iphoneX" className="img-fluid mockup_1"/>
                                                        <img src="/assets/images/akountGraphics/add_product.png" alt="iphoneX" className="img-fluid mockup_2"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-12 col-12">
                                                    <div className="oppi_mockup_content">
                                                        <h3 className="title">Manage Your Inventory Effortlessly with Akount App</h3>
                                                        <p className="para">Akount revolutionizes inventory management by offering a versatile mobile app. Whether you're at your desk, on the shop floor, or out meeting clients, our app ensures you have complete control over your inventory at all times.</p>
                                                        <p className="para">Say goodbye to the complexities of traditional inventory management systems. With Akount, you can effortlessly track products, monitor stock levels, and make informed decisions on the fly. Experience the convenience and efficiency of mobile inventory management with Akount.</p>
                                                        <Link to="#" className="oppi_dbtn btn_border">Try it Free</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="Contacts" role="tabpanel">
                                <div className="oppi_mobile_mockup_slider">
                                    <div className="oppi_mobile_mockup_slider_item">
                                        <div className="container">
                                            <div className="row d-flex align-items-center">
                                                <div className="col-md-6 col-sm-12 col-12">
                                                    <div className="oppi_mobile_mockup">
                                                        <img src="/assets/images/akountGraphics/accounts_suppliers.png" alt="iphoneX" className="img-fluid mockup_1"/>
                                                        <img src="/assets/images/akountGraphics/accounts_customers.png" alt="iphoneX" className="img-fluid mockup_2"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-12 col-12">
                                                    <div className="oppi_mockup_content">
                                                        <h3 className="title">Efficient Customer Management Anywhere, Anytime with the Akount App</h3>
                                                        <p className="para">With the Akount app, you can effortlessly manage your customers no matter where you are. Whether you're in the office, on the go, or enjoying a break, our app ensures seamless customer management.</p>
                                                        <p className="para">Gone are the days of cumbersome customer tracking methods. Akount simplifies customer management, allowing you to maintain detailed records, track interactions, and provide exceptional service with ease.</p>
                                                        <Link to="#" className="oppi_dbtn btn_border">Try it Free</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="Employee" role="tabpanel">
                                <div className="oppi_mobile_mockup_slider">
                                    <div className="oppi_mobile_mockup_slider_item">
                                        <div className="container">
                                            <div className="row d-flex align-items-center">
                                                <div className="col-md-6 col-sm-12 col-12">
                                                    <div className="oppi_mobile_mockup">
                                                        <img src="/assets/images/akountGraphics/attendance.png" alt="iphoneX" className="img-fluid mockup_1"/>
                                                        <img src="/assets/images/akountGraphics/employees.png" alt="iphoneX" className="img-fluid mockup_2"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-12 col-12">
                                                    <div className="oppi_mockup_content">
                                                        <h3 className="title">Effortless Employee & workers Management with Akount App</h3>
                                                        <p className="para">Manage your employees seamlessly no matter where you are with the Akount app. Whether you're in the office, on the move, or taking a break, our app ensures smooth employee management.</p>
                                                        <p className="para">Forget about complicated employee tracking systems. Akount simplifies employee management, allowing you to handle scheduling, track hours, and oversee tasks with ease.</p>
                                                        <Link to="#" className="oppi_dbtn btn_border">Try it Free</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="Payments" role="tabpanel">
                                <div className="oppi_mobile_mockup_slider">
                                    <div className="oppi_mobile_mockup_slider_item">
                                        <div className="container">
                                            <div className="row d-flex align-items-center">
                                                <div className="col-md-6 col-sm-12 col-12">
                                                    <div className="oppi_mobile_mockup">
                                                        <img src="/assets/images/akountGraphics/payments_all.png" alt="iphoneX" className="img-fluid mockup_1"/>
                                                        <img src="/assets/images/akountGraphics/payments_received.png" alt="iphoneX" className="img-fluid mockup_2"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-12 col-12">
                                                    <div className="oppi_mockup_content">
                                                        <h3 className="title">Manage Your Payments with one click</h3>
                                                        <p className="para">With the Akount app, payment management is effortless no matter where you are. Whether you're in the office, on the move, or enjoying some downtime, our app ensures seamless payment management.</p>
                                                        <p className="para">Forget about the hassle of traditional payment methods. Akount simplifies payment management, allowing you to track transactions, monitor due dates, and handle invoices with ease.</p>
                                                        <Link to="#" className="oppi_dbtn btn_border">Try it Free</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="Reporting" role="tabpanel">
                                <div className="oppi_mobile_mockup_slider">
                                    <div className="oppi_mobile_mockup_slider_item">
                                        <div className="container">
                                            <div className="row d-flex align-items-center">
                                                <div className="col-md-6 col-sm-12 col-12">
                                                    <div className="oppi_mobile_mockup">
                                                        <img src="/assets/images/akountGraphics/sales_report.png" alt="iphoneX" className="img-fluid mockup_1"/>
                                                        <img src="/assets/images/akountGraphics/expenses_report.png" alt="iphoneX" className="img-fluid mockup_2"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-12 col-12">
                                                    <div className="oppi_mockup_content">
                                                        <h3 className="title">Access Comprehensive Reports anytime with the Akount App</h3>
                                                        <p className="para">With the Akount app, generating insightful reports is effortless no matter where you are. Whether you're at your desk, on the move, or taking a break, our app ensures seamless access to comprehensive reports.</p>
                                                        <p className="para">Forget about the complexities of traditional reporting methods. Akount simplifies the process, allowing you to generate detailed financial reports, track trends, and make informed decisions with ease.</p>
                                                        <Link to="#" className="oppi_dbtn btn_border">Try it Free</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <button className="prevtab tab_custom_arrow btn" ><i className="arrow_carrot-left"></i></button>
                    <button className="nexttab tab_custom_arrow btn" ><i className="arrow_carrot-right"></i></button> */}
                </div>
            </section>
            {/* <!-- oppi_how_it_works_wrap --> */}

            <section className="oppi_our_service_wrap">
                <div className="shape_1">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="1193px" height="1084px">
                        <path fillRule="evenodd" fill="rgb(250, 250, 253)"
                            d="M561.000,27.999 C829.693,326.579 1192.000,-44.280 1192.000,231.834 C1192.000,471.284 1228.896,1587.402 824.000,815.999 C643.614,472.330 395.682,793.343 60.859,758.781 C-147.174,737.307 256.465,455.100 216.000,274.999 C174.123,88.616 478.880,-63.255 561.000,27.999 Z" />
                    </svg>
                </div>
                <div className="shape_2">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="1254px" height="1230px">
                        <path fillRule="evenodd" fill="rgb(250, 250, 253)"
                            d="M621.000,274.999 C782.008,789.590 1254.000,418.857 1254.000,694.999 C1254.000,971.141 850.907,1280.621 123.000,1221.999 C-152.251,1199.831 121.000,971.141 121.000,694.999 C121.000,418.857 399.351,-433.402 621.000,274.999 Z" />
                    </svg>
                </div>
                <div className="container">
                    <div className="row oppi_service_item oppi_service_img_right">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="oppi_service_content">
                                <h3 className="title wow fadeInUp" data-wow-delay="0.2s">Backup for safeguarding against data loss</h3>
                                <h5 className="servece_note wow fadeInUp" data-wow-delay="0.3s">Full control over your data, backup data to your own Google account for permanent retention</h5>
                                <p className="service_para wow fadeInUp" data-wow-delay="0.4s">Forget about the confusion and uncertainty. With Akount's backup feature, safeguarding your business data is as simple as pie. Don't risk losing valuable information. Instead, ensure continuity and peace of mind with Akount's easy-to-use backup solution.</p>
                                <Link to="#" className="take_tour wow fadeInUp" data-wow-delay="0.5s">Take a Tour <i className="arrow_right"></i></Link>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="oppi_service_img_content img_right">
                                <div className="service_img_1 wow fadeInUp" data-wow-delay="0.3s" >
                                    <img src="/assets/images/akountGraphics/backup_1.png" alt="service_img" className="img-fluid" data-parallax='{"x": 0, "y": 50}'/>
                                </div>
                                <div className="service_img_2 wow fadeInUp" data-wow-delay="0.4s" >
                                    <img src="/assets/images/akountGraphics/backup.png" alt="service_img" className="img-fluid" data-parallax='{"x": 0, "y": -50}'/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row oppi_service_item oppi_service_img_left">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="oppi_service_img_content img_left">
                                <div className="service_img_1 wow fadeInUp" data-wow-delay="0.2s" >
                                    <img src="/assets/images/akountGraphics/security_1.png" alt="service_img" className="img-fluid" data-parallax='{"x": 0, "y": 50}'/>
                                </div>
                                <div className="service_img_2 wow fadeInUp" data-wow-delay="0.3s" >
                                    <img src="/assets/images/akountGraphics/security_2.png" alt="service_img" className="img-fluid" data-parallax='{"x": 0, "y": -50}'/>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 offset-lg-1 col-md-6 col-sm-12 col-12">
                            <div className="oppi_service_content">
                                <h3 className="title wow fadeInUp" data-wow-delay="0.2s">Protect Your Data with Fingerprint or PIN Lock</h3>
                                <p className="service_para wow fadeInUp" data-wow-delay="0.3s">Your financial data is precious, and we understand the importance of keeping it safe. With Akount's advanced security features, including fingerprint and PIN lock, rest assured that your information remains secure and accessible only to you. Say goodbye to worries about unauthorized access—keep your finances protected with Akount.</p>
                                <Link to="#" className="take_tour wow fadeInUp" data-wow-delay="0.5s">Take a Tour <i className="arrow_right"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- oppi_our_service_wrap --> */}

            <section className="oppi_call_to_action_wrap">
                <div className="shape_1">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="1254px" height="743px" data-parallax='{"x": 0, "y": -100}'>
                        <path fillRule="evenodd" opacity="0.051" fill="rgb(255, 255, 255)"
                            d="M916.000,10.999 C1500.430,-86.677 1231.509,514.891 816.000,512.999 C356.848,510.910 502.840,754.162 260.511,741.564 C0.011,728.022 62.095,691.726 22.895,455.481 C-45.270,44.671 676.663,51.000 916.000,10.999 Z" />
                    </svg>
                </div>
                <div className="shape_2">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="1307px" height="752px" data-parallax='{"x": 0, "y": 100}'>
                        <path fillRule="evenodd" opacity="0.051" fill="rgb(255, 255, 255)"
                            d="M1103.000,47.999 C1247.334,215.243 1583.343,536.009 852.000,535.998 C433.985,535.992 457.940,763.014 237.324,751.541 C0.166,739.208 56.687,706.152 21.000,490.999 C-41.057,116.863 973.138,-102.475 1103.000,47.999 Z" />
                    </svg>
                </div>
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-7 col-md-7 col-sm-12 col-12">
                            <div className="oppi_call_to_action_content">
                                <h1 className="call_title wow fadeInUp" data-wow-delay="0.2s">Did you know? The Akount app works 100% offline.</h1>
                                <p className="call_para wow fadeInUp" data-wow-delay="0.3s">Complete offline app, ensures data privacy and instant access to all features without internet lag time or buffering. Don't wait any longer—download the app now and start using it for free!</p>
                                <div className="oppi_btn_group">
                                    <Link to="https://play.google.com/store/apps/details?id=com.ispl.accounts&hl=en-IN" className="oppi_dbtn btn_solid_color  wow fadeInUp" data-wow-delay="0.4s"> <span className="iconify" data-icon="fa-brands:google-play" data-inline="false"></span> Download for Free</Link>
                                    {/* <Link to="#" className="oppi_dbtn btn_border  wow fadeInUp" data-wow-delay="0.5s" title="Comming Soon"> <span className="iconify" data-icon="ant-design:apple-fill" data-inline="false"></span> App Store</Link> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-5 col-sm-12 col-12">
                            <div className="oppi_call_to_action_img">
                                <div className="business_call_img_group">
                                    <img src="/assets/images/call_to_action/business/man_with_laptop.png" alt="call_to_action_img" className="man_with_laptop img-fluid wow fadeInRight" data-wow-delay="0.2s"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- oppi_call_to_action_wrap --> */}

            <section className="oppi_support_wrap" id="oppi_support_wrap">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="oppi_support_content">
                                <h3 className="title wow fadeInUp" data-wow-delay="0.2s">Dedicated customer support team is here to assist you</h3>
                                <p className="support_para wow fadeInUp" data-wow-delay="0.3s">At Akount, we prioritize your success and satisfaction above all else. Our dedicated customer support team is here to assist you every step of the way, ensuring that you receive prompt and personalized assistance whenever you need it.</p>
                                <div className="oppi_support_contant">
                                    <h5 className="contact_title wow fadeInUp" data-wow-delay="0.4s">Contact us today</h5>
                                    <ul className="support_contact_list">
                                        <li>
                                            <Link to="#" className=" wow fadeInUp" data-wow-delay="0.5s"> <span className="icon-call-out"></span> +91 8299886767 </Link>
                                        </li>
                                        <li>
                                            <Link to="#" className=" wow fadeInUp" data-wow-delay="0.6s"> <span className="icon-envelope"></span> info@inventics.tech </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="oppi_support_img">
                                <div className="img_inner">
                                    <div className="shape_1 wow fadeInDown" data-wow-delay="0.6s"></div>
                                    <div className="shape_2 wow fadeInRight" data-wow-delay="0.8s">
                                        <div className="shape_2_inner"></div>
                                    </div>
                                    <img src="/assets/images/support_img.jpg" alt="support_img" className="img-fluid wow fadeInUp" data-wow-delay="0.2s"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- oppi_support_wrap --> */}

            {/* <section className="oppi_our_client_wrap">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-12">
                            <div className="oppi_client_content">
                                <h2 className="sec_title text-center  wow fadeInUp" data-wow-delay="0.2s">Do your best work <br /> with best-of-breed tools</h2>
                                <p className="text-center client_para  wow fadeInUp" data-wow-delay="0.3s">Chinwag give us a bell brown bread the wireless that so I said, bugger knees up blow off Richard, do one tosser porkies blatant. Boot bubble and squeak bleeding lurgy.!</p>
                                <div className="oppi_btn_group text-center">
                                    <Link to="/our-partners" className="oppi_dbtn btn_solid  wow fadeInUp" data-wow-delay="0.4s">View all Partners</Link>
                                    <Link to="#" className="oppi_dbtn btn_icon  wow fadeInUp" data-wow-delay="0.6s"> <i className="icon_plus_alt2"></i> <span>Become a Partner</span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-12">
                            <div className="oppi_cleint_logo_content">
                                <ul className="client_logo_list">
                                    <li className="wow slideInnew2" data-wow-delay="0.2s"><img src="https://images.crunchbase.com/image/upload/c_pad,f_auto,q_auto:eco,dpr_1/awctus1vmxgf69qyr0uu" alt="client_logo" /></li>
                                    <li className="wow slideInnew2" data-wow-delay="0.3s"><img src="https://www.ntscompany.net/web/image/2854-28b61ef9/Asset%202.png" alt="client_logo" /></li>
                                    <li className="wow slideInnew2" data-wow-delay="0.4s"><img src="/assets/images/client_logo/03.png" alt="client_logo" /></li>
                                    <li className="wow slideInnew2" data-wow-delay="0.5s"><img src="/assets/images/client_logo/04.png" alt="client_logo" /></li>
                                    <li className="wow slideInnew2" data-wow-delay="0.6s"><img src="/assets/images/client_logo/05.png" alt="client_logo" /></li>
                                    <li className="wow slideInnew2" data-wow-delay="0.7s"><img src="/assets/images/client_logo/06.png" alt="client_logo" /></li>
                                    <li className="wow slideInnew2" data-wow-delay="0.6s"><img src="/assets/images/client_logo/07.png" alt="client_logo" /></li>
                                    <li className="wow slideInnew2" data-wow-delay="0.6s"><img src="/assets/images/client_logo/08.png" alt="client_logo" /></li>
                                    <li className="wow slideInnew2" data-wow-delay="0.5s"><img src="/assets/images/client_logo/09.png" alt="client_logo" /></li>
                                    <li className="wow slideInnew2" data-wow-delay="0.4s"><img src="/assets/images/client_logo/10.png" alt="client_logo" /></li>
                                    <li className="wow slideInnew2" data-wow-delay="0.5s"><img src="/assets/images/client_logo/11.png" alt="client_logo" /></li>
                                    <li className="wow slideInnew2" data-wow-delay="0.6s"><img src="/assets/images/client_logo/12.png" alt="client_logo" /></li>
                                    <li className="wow slideInnew2" data-wow-delay="0.7s"><img src="/assets/images/client_logo/13.png" alt="client_logo" /></li>
                                    <li className="wow slideInnew2" data-wow-delay="0.5s"><img src="/assets/images/client_logo/14.png" alt="client_logo" /></li>
                                    <li className="wow slideInnew2" data-wow-delay="0.4s"><img src="/assets/images/client_logo/15.png" alt="client_logo" /></li>
                                    <li className="wow slideInnew2" data-wow-delay="0.7s"><img src="/assets/images/client_logo/16.png" alt="client_logo" /></li>
                                    <li className="wow slideInnew2" data-wow-delay="0.2s"><img src="/assets/images/client_logo/17.png" alt="client_logo" /></li>
                                    <li className="wow slideInnew2" data-wow-delay="0.4s"><img src="/assets/images/client_logo/18.png" alt="client_logo" /></li>
                                    <li className="wow slideInnew2" data-wow-delay="0.6s"><img src="/assets/images/client_logo/19.png" alt="client_logo" /></li>
                                    <li className="wow slideInnew2" data-wow-delay="0.7s"><img src="/assets/images/client_logo/20.png" alt="client_logo" /></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <!-- oppi_our_client_wrap --> */}

            <section className="oppi_download_app_wrap" id="oppi_download_app_wrap">
                <div className="container">
                    <div className="row col_reverse">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 oppi_mockup_img">
                            <div className="mockup_inner">
                                <div className="circle_1"></div>
                                <div className="circle_2"></div>
                                <div className="download_1 wow fadeInUp" data-wow-delay="0.2s">
                                    <img src="/assets/images/akountGraphics/download_1.png" alt="download_mockup" className="img-fluid" data-parallax='{"x": 0, "y": 20}'/>
                                </div>
                                <div className="download_2 wow fadeInUp" data-wow-delay="0.5s">
                                    <img src="/assets/images/akountGraphics/download_2.png" alt="download_mockup" className="img-fluid" data-parallax='{"x": 0, "y": 60}'/>
                                </div>
                                
                                
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="oppi_download_app_content m_w_475">
                                <h2 className="sec_title  wow fadeInUp" data-wow-delay="0.2s">Carry the Power of Akount in Your Pocket</h2>
                                <p className="download_para  wow fadeInUp" data-wow-delay="0.3s">With Akount, all the capabilities you need are conveniently available on your smartphone. Experience the freedom of managing your finances on the go with Akount. <br></br>Download app now!</p>
                                <div className="oppi_btn_group">
                                    <Link to="https://play.google.com/store/apps/details?id=com.ispl.accounts&hl=en-IN" className="oppi_dbtn btn_solid_color  wow fadeInUp" data-wow-delay="0.4s"> <span className="iconify" data-icon="fa-brands:google-play" data-inline="false"></span> Google play</Link>
                                    {/* <Link to="#" className="oppi_dbtn btn_border  wow fadeInUp" data-wow-delay="0.5s" title="Comming Soon"> <span className="iconify" data-icon="ant-design:apple-fill" data-inline="false"></span> App Store</Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- oppi_download_app_wrap --> */}
        </>
    )
}

export default Home;