import React,{useEffect} from "react";

function Partners(){
    useEffect(() => {
        const header = document.querySelector('.oppi_header');
    
        const handleScroll = () => {
            if (!header.classList.contains('fixedMenu')) {
                header.classList.add('fixedMenu');
              }
          } 
        // Call handleScroll on page load
        handleScroll();
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);


    return(
        <>
        <div className="body2">
            <div className="d-flex justify-content-center">
                <h3>Our Partners</h3>
            </div>
        </div>
        </>
    )
}
export default Partners;