import React,{useEffect} from "react";

function TermsConditions(){
    useEffect(() => {
        const header = document.querySelector('.oppi_header');
    
        const handleScroll = () => {
            if (!header.classList.contains('fixedMenu')) {
                header.classList.add('fixedMenu');
              }
          } 
        // Call handleScroll on page load
        handleScroll();
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
    return (
        <>
       <section className="oppi_feature_wrap">
            <div className="d-flex justify-content-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-12">
                            <h2 className="text-center sec_title wow fadeInUp" data-wow-delay="0.2s">Terms and Conditions</h2>
                            <p>Please read these Terms and Conditions ("Terms") carefully before using the Akount mobile application ("App") </p>
                            <span><b>1. Acceptance of Terms</b></span>
                            <p className="para">By accessing or using the App, you agree to be bound by these Terms. If you do not agree to all the terms and conditions of this agreement, you may not access or use the App.</p>
                            <span><b>2. Use License</b></span>
                            <p className="para">Permission is granted to download and use the App solely for personal, non-commercial use. This is the grant of a license, not a transfer of title.
                            You may not modify, reproduce, distribute, or sell any part of the App without our prior written consent.</p>
                            <span><b>3. User Account</b></span>
                            <p className="para">To access certain features of the App, you may be required to create an account. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your device.
                            You agree to accept responsibility for all activities that occur under your account.</p>
                            <span><b>4. Content</b></span>
                            <p className="para">The App may contain user-generated content. We do not guarantee the accuracy, completeness, or reliability of any content posted by users.
You agree not to post any content that is unlawful, defamatory, infringing, or otherwise objectionable.
</p>
                            <span><b>5. Privacy</b></span>
                            <p className="para">Your use of the App is subject to our Privacy Policy. By using the App, you consent to the collection and use of information as described in the Privacy Policy.
</p>
                            <span><b>6. Limitations of Liability</b></span>
                            <p className="para">In no event shall we be liable for any damages arising out of the use or inability to use the App, even if we have been advised of the possibility of such damages.
We do not warrant that the App will be error-free, uninterrupted, or secure.
</p>
                            <span><b>7. Indemnification</b></span>
                            <p className="para">You agree to indemnify and hold us harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of your breach of these Terms or your violation of any law or the rights of a third party.
</p>
                            <span><b>8. Changes to Terms</b></span>
                            <p className="para">We reserve the right to modify or replace these Terms at any time. Any changes will be effective immediately upon posting the revised Terms on the App. Your continued use of the App after any such changes constitutes your acceptance of the new Terms.
</p>
                            <span><b>9. Contact Us</b></span>
                            <p className="para">If you have any questions about these Terms, please contact us at info@inventics.tech
</p>
                            * By using our App, you signify your acceptance of these Terms.
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}
export default TermsConditions;