import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { preapprovedTemplate } from "../js/utils";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function HomeBanner(){
    const messageTxt = (message) => toast(message);

    const [getNumber, setGetNumber] = useState({
        number: "",
    });

    const handleChangeValue = (e) => {
        const { name, value } = e.target;
        setGetNumber({ ...getNumber, [name]: value });
    };

    const handleSendTemplate = async (e) => {
        e.preventDefault();
        const { number } = getNumber;

        if (!number) {
            messageTxt("Please enter a number!");
            return false;
        }

        if (number.length > 10 || number.length < 10) {
            messageTxt("Mobile number should be 10 digits!");
            return false;
        }

        const result =  await preapprovedTemplate(number);

        if (result.messages[0].message_status === 'accepted') {
            setGetNumber({
                number: ''
            });
            messageTxt("Please check your whatsapp!");
            return false;
        }

    };

    useEffect(() => {
        // console.log(getNumber);
    }, [getNumber]);

    return (
        <>
        
        <section className="oppi_banner oppi_banner_one">
                <div className="overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-12 col-sm-12 col-12 full_width_1024">
                            <div className="oppi_banner_content">
                                <h1 className="banner_title  wow fadeInUp" data-wow-delay="0.2s">Complete <span>Business Accounting</span> App</h1>
                                <p className="banner_para  wow fadeInUp" data-wow-delay="0.4s">Akount is your ultimate tool for efficient financial management. <br></br>Akount simplifies invoicing, tracks payments, manages expenses, monitors inventory, and maintains customer records i.e. all in one place.
</p>
                                <form className="mailchimp email_box wow fadeInUp" data-wow-delay="0.5s">
                                    <div className="input-group subcribes">
                                    <input
                                        name="number"
                                        onChange={handleChangeValue}
                                        className="form-control memail"
                                        type="text"
                                        value={getNumber.number}
                                        placeholder="Enter your whatsapp number"
                                        onFocus={(e) => (e.target.placeholder = "")}
                                        onBlur={(e) =>
                                            (e.target.placeholder = "Enter your whatsapp number")
                                        }
                                    />

                                        <button className="btn oppi_btn" onClick={handleSendTemplate}>Get Started</button>
                                    </div>
                                    <p className="mchimp-errmessage" style={{ display: 'none' }}></p>
                                    <p className="mchimp-sucmessage" style={{ display: 'none' }}></p>
                                </form>
                                <div className="video_play d-flex align-items-center  wow fadeInUp" data-wow-delay="0.6s">
                                    <Link className="popup-youtube video_icon" to="https://www.youtube.com/watch?v=sU3FkzUKHXU">
                                        <div className="video_play_icon">
                                            <i className="fa fa-play"></i>
                                        </div>
                                        <span className="play_text">Watch Video</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-12 col-sm-12 col-12 full_width_1024">
                            <div className="oppi_banner_img_content">
                                <div className="banner_img">
                                    <div className="wow fadeInUp" data-wow-delay="0.3s"><img src="/assets/images/banner/banner.png" alt="macbook" className="banner_img_macbook" data-parallax='{"x": 0, "y": 80}'/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
        <ToastContainer />
        </>
    );
}
export default HomeBanner;