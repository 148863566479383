import './App.css';
import { Routes, Route } from "react-router-dom";
import Layout from './layout/Layout';
import Home from './pages/Home';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsConditions from './pages/TermsConditions';
import Partners from './pages/Partners';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path='/' element={<Home />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/terms-conditions' element={<TermsConditions />} />
        <Route path='/our-partners' element={<Partners/>} />
      </Route>
    </Routes>
  );
}

export default App;
