import React from 'react'
import {Link} from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="oppi_footer_wrap">
            <div className="circle_shape_1"></div>
            <div className="circle_shape_2"></div>
            {/* <div className="oppi_footer_top">
                <div className="container">
                    <div className="oppi_footer_top_inner">
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                <div className="oppi_footer_widget">
                                    <Link to="#" className="oppi_footer_logo">
                                        <img src="/assets/images/akount2.png" alt="logo"/>
                                    </Link>
                                    <p className="footer_para"> Are you taking the piss brolly William the BBC fanny around it's all gone to pot barney bubble and squeak, </p>
                                    <p className="footer_para"> Bamboozled brown bread no biggie sloshed, spiffing.</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                <div className="oppi_footer_widget pl_100">
                                    <h4 className="oppi_footer_title">Features</h4>
                                    <ul className="oppi_footer_menu">
                                        <li><Link to="#">Analytics</Link></li>
                                        <li><Link to="#">Activity</Link></li>
                                        <li><Link to="#">Content</Link> </li>
                                        <li><Link to="#">Management </Link></li>
                                        <li><Link to="#">Publishing</Link></li>
                                        <li><Link to="#">Influencers</Link></li>
                                        <li><Link to="#">Online Payments</Link></li>
                                        <li><Link to="#">Android app</Link></li>
                                        <li><Link to="#">iPhone app</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                <div className="oppi_footer_widget pl_100">
                                    <h4 className="oppi_footer_title">About Us</h4>
                                    <ul className="oppi_footer_menu">
                                        <li><Link to="#">Our Story</Link></li>
                                        <li><Link to="#">Invoice Software</Link></li>
                                        <li><Link to="#">Time Tracking</Link></li>
                                        <li><Link to="#">Blog</Link></li>
                                        <li><Link to="#">About Us</Link></li>
                                        <li><Link to="#">Careers</Link></li>
                                        <li><Link to="#">Press Center</Link></li>
                                        <li><Link to="#">Contact Us</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                <div className="oppi_footer_widget pl_100">
                                    <h4 className="oppi_footer_title">Helpful Links</h4>
                                    <ul className="oppi_footer_menu">
                                        <li><Link to="#">Accounting Software</Link></li>
                                        <li><Link to="#">Privacy Policy</Link></li>
                                        <li><Link to="#">Terms of Service</Link></li>
                                        <li><Link to="#">Support</Link></li>
                                        <li><Link to="#">Accessibility</Link></li>
                                        <li><Link to="#">Sitemap</Link></li>
                                        <li><Link to="#">Invoice Template</Link></li>
                                        <li><Link to="#">Login</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="oppi_footer_bottom">
                <div className="container">
                    <div className="oppi_footer_bottom_inner">
                        <div className="row d-flex align-items-center col_reverse">
                            <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                                <div className="oppi_copy_right">
                                    <p className="copy_right_text">© Copyright 2024 Powered by <Link to="http://www.inventics.tech/" target="_blank">INVENTICS</Link></p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                                <div className="d-flex justify-content-center text text-white">
                                    <Link to="/privacy-policy">Privacy Policy</Link>&emsp;
                                    <Link to="/terms-conditions">Terms & Conditons</Link>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                                <div className="oppi_footer_social">
                                    <ul className="social_item text-right">
                                        <li><Link to="https://www.facebook.com/inventics.tech"><i className="social_facebook"></i></Link></li>
                                        <li><Link to="https://twitter.com/inventicsindia"><i className="social_twitter"></i></Link></li>
                                        <li><Link to="https://www.instagram.com/inventicsindia"><i className="social_instagram"></i></Link></li>
                                        <li><Link to="https://in.linkedin.com/company/inventicstech"><i className="social_linkedin"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
